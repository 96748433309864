import React from 'react'
import Helmet from "react-helmet"

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

import './base.scss'
import ContactSection from '../components/ContactSection'

export default function BaseLayout({ children }) {
  return (
    <>
      <Helmet>
        <title>SolveQ Software Development IoT React Node Apps</title>
      </Helmet>
      <Header />
      <div className="th-page-content">{children}</div>
      <br/>
      <br/>
      <br/>
      <ContactSection/>
      <Footer />
    </>
  );
}
