import React, { useEffect, useState } from 'react';
import { animateScroll } from 'react-scroll';
import { MdExpandLess, MdPlace, MdLocalPhone, MdEmail } from 'react-icons/md';
import { Link } from 'gatsby';

import './Footer.scss';

const Footer = () => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    setTop(100);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className="th-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-5">
            <div className="th-footer-about">
              <Link className="th-footer-about__logo-link" to="/">
                <img src="/solveq_logo.png" alt="logo images" className="img-fluid" />
              </Link>
              <p className="th-footer-about__paragraph">
                We are SolveQ, Agile Software House Based in Gdynia established in 2018. We know that our contribution
                has true impact on the company of yours and will last for years to come.
              </p>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-2 col-md-7">
            <div className="th-footer-contact">
              <h3 className="th-footer-contact__header">Contact</h3>
              <p>
                <MdPlace size={20} />
                <span>Mikołaja Kopernika 54, 81-436 Gdynia, Poland</span>
              </p>
              <p>
                <MdLocalPhone size={20} />
                <span>+48 501 959 657</span>
              </p>
              <p>
                <MdEmail size={20} />
                <span>
                  <a href="mailto: info@solveq.io">info@solveq.io</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="th-footer-map">
        <img className="th-footer-map__image" src="/bg/footer_map.png" alt="" />
      </div>
      <button
        type="button"
        className={`th-footer__btn-scroll-top ${scroll > top ? 'th-footer__btn-scroll-top--show' : ''}`}
        onClick={() => scrollToTop()}
      >
        <MdExpandLess />
      </button>
    </footer>
  );
};

export default Footer;
