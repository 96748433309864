import React from 'react';
import { Link } from 'gatsby';

import './Navigation.scss';

const Navigation = () => {
  return (
    <nav className="th-nav">
      <ul>
        <li className="th-nav__list-item--has-children">
          <Link to="/#" activeClassName="active">
            What we do
          </Link>
          <ul>
            <li>
              <Link to="/internet-of-things" activeClassName="active">
                Internet of things
              </Link>
            </li>
            <li>
              <Link to="/digital-transformation" activeClassName="active">
                Digital Transformation
              </Link>
            </li>
            <li>
              <Link to="/new-apps-and-services" activeClassName="active">
                New Apps and Services
              </Link>
            </li>
          </ul>
        </li>

        <li className="th-nav__list-item--has-children">
          <Link to="/about" activeClassName="active">
            About us
          </Link>
          <ul>
            <li>
              <Link to="/the-way-we-work" activeClassName="active">
                The way we work
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/case-studies" activeClassName="active">
            Case studies
          </Link>
        </li>

        <li>
          <Link to="/careers" activeClassName="active">
            Careers
          </Link>
        </li>
        <li>
          <Link to="/blog" activeClassName="active">
            Blog
          </Link>
        </li>
        <li>
          <Link to="/contact" activeClassName="active">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
